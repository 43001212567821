/* --------------------------------------ADMIN-------------------------------------- */
.admin-sidebar .links a.active {
  @apply rounded-3xl bg-accentpurple font-semibold text-blue 2xl:px-6 2xl:py-4 lg:px-5 lg:py-3 sm:p-3;
}

.admin-sidebar .links a svg {
  @apply text-2xl;
}

/* --------------------------------------MANAGER-------------------------------------- */
.manager-sidebar .links a.active {
  @apply rounded-3xl bg-accentpurple font-semibold text-blue 2xl:px-6 2xl:py-4 lg:px-5 lg:py-3;
}

.manager-sidebar .links a svg {
  @apply text-2xl;
}

/* --------------------------------------CASHIER-------------------------------------- */
.cashier-sidebar .links a.active {
  @apply rounded-2xl bg-accentpurple font-semibold text-blue;
}

.cashier-sidebar .links a svg {
  @apply text-4xl;
}
