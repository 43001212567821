.item > form label {
  @apply text-xl text-fadegrey;
}

.item > form input,
.item > form select {
  @apply mb-6 mt-1 w-72 rounded-lg bg-accentpurple p-2 font-semibold text-blue outline-none;
}

.item > form input:read-only,
.item > form select:read-only {
  @apply text-gray-500;
}

.item > form > div:first-child input {
  @apply w-full;
}

.item > form > div:nth-child(2) {
  @apply flex justify-center gap-8;
}
