/**
  * Adding tailwind support
  */
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --background-color: #f5f5f5;
  --blue: #090263;
  --accent-purple: #f5edff;
  --white: #ffffff;
  --fade-grey: #8a8990;
  --faded-black: #44416df7;
  --lighter-purple: #1003a8;
  --popup-background-color: rgba(29, 29, 29, 0.79);
}

body {
  font-family: "Montserrat", sans-serif;
  background-color: var(--accent-purple);
  color: var(--blue);
}

/* Customize the Firefox scrollbar */
* {
  scrollbar-color: var(--blue) transparent;
  scrollbar-width: thin;
  scroll-behavior: smooth;
  scroll-snap-type: proximity;
}

/* Style the thumb (scrollbar handle) */
*::-webkit-scrollbar-thumb {
  width: 0.3rem;
  background-color: var(--blue);
  border-radius: 1rem;
  position: relative;
  margin: 2rem;
}

/* Width of the scrollbar track and thumb */
/* *::-webkit-scrollbar {
  width: 0.3rem;
} */

/* a {
  color: var(--blue);
  text-decoration: none;
} */

/* button,
input[type="submit"] {
  outline: none;
  border: none;
} */
