.admin-store-profile > form label,
.admin-store-profile .center label,
.admin-store-profile .center div.heading {
  @apply font-semibold uppercase text-fadegrey;
}

.admin-store-profile > form input,
.admin-store-profile > form select {
  @apply mb-6 mt-1 w-72 rounded-lg bg-accentpurple p-2 font-semibold text-blue outline-none;
}

.admin-store-profile > form input:read-only,
.admin-store-profile > form select:read-only {
  @apply text-gray-600;
}
