.pagetitle .breadcrumb {
  display: flex;
  margin-bottom: 20px;
  margin-top: 10px;
  gap: 5px;
}
.pagetitle .breadcrumb .arrow {
  font-family: monospace;
}
.pagetitle ul,
.pagetitle ol {
  list-style: none;
}

.grid2 {
  display: grid;
  grid-template-columns: 1fr 33.33%;
  gap: 20px;
}

.section.dashboard {
  width: 100%;
}
.section.dashboard .card {
  border-radius: 1rem;
  background: white;
  padding: 20px;
  box-shadow: 0 0 8px rgba(250, 248, 248, 0.589);
}
.section.dashboard ul,
.section.dashboard ol {
  list-style: none;
}
.section.dashboard .overview {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}
.section.dashboard .col-lg-8 .row {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.section.dashboard .filter {
  position: absolute;
  display: none;
}
.section.dashboard .card-title {
  font-size: 1.2rem;
  font-weight: 600;
  opacity: 0.9;
  margin-bottom: 10px;
}
.section.dashboard .card-title span {
  opacity: 0.6;
}
.section.dashboard .ps-3 {
  font-size: 1.4rem;
}
.section.dashboard .ps-3 h6 {
  font-size: 1.8rem;
}
.section.dashboard .d-flex {
  display: flex;
  align-items: center;
  gap: 20px;
}
.section.dashboard .d-flex .text-success {
  color: #6f6ff5;
}
.section.dashboard .d-flex .card-icon {
  padding: 10px;
  background: rgba(215, 190, 238, 0.8156862745);
  border-radius: 50%;
}
.section.dashboard .activity-item {
  margin: 10px 10px;
}
.section.dashboard .table.table-borderless {
  width: 100%;
}
.section.dashboard table {
  text-align: left;
}
.section.dashboard table tbody tr td,
.section.dashboard table tbody tr th,
.section.dashboard table thead tr td,
.section.dashboard table thead tr th {
  padding: 10px !important;
}
.section.dashboard table tbody th,
.section.dashboard table thead th {
  font-variant: small-caps;
}
.section.dashboard .bg-warning {
  color: #f5a06f;
}
.section.dashboard .text-primary {
  color: #6f6ff5;
}
.section.dashboard .bg-success {
  color: #6ff591;
}
.section.dashboard .bg-danger {
  color: #f56f91;
}
.section.dashboard .bg-danger,
.section.dashboard .bg-success,
.section.dashboard .bg-warning {
  font-weight: 600;
}
.section.dashboard .post-item h4 {
  font-size: 1.3rem;
  opacity: 0.6;
  margin-bottom: 5px;
  text-decoration: underline;
}
.section.dashboard .post-item p {
  margin-left: 10px;
  position: relative;
}
.section.dashboard .post-item p::before {
  position: absolute;
  content: "";
  left: -10px;
  width: 5px;
  height: 100%;
  background: #f56f91;
} /*# sourceMappingURL=HomeUi.css.map */
