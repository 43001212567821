.webcam-container {
  position: absolute;
  right: 0;
  bottom: 0;
  margin-right: 4vw;
  margin-bottom: 23vh;
  background-color: var(--blue);
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  padding: 0 1rem;
  border-radius: 0.5rem;
}

.webcam-container div {
  /* margin-right: 3rem; */
}

.webcam-container div svg {
  background-color: var(--accent-purple);
  font-size: 2rem;
  cursor: pointer;
  margin-bottom: 0.5rem;
}

.webcam-container div svg:first-child {
  margin-right: 3rem;
}
