.new-product form label {
  @apply text-xl text-fadegrey;
}

.new-product form input,
.new-product form select {
  @apply mb-6 mt-1 w-72 rounded-lg border-none bg-accentpurple p-2 font-semibold text-blue outline-none;
}

/* .barcode svg {
  background-color: var(--blue);
  color: white;
  font-size: 3rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
} */
