.pagetitle {
  // padding: 20px;
  .breadcrumb {
    display: flex;
    margin-bottom: 20px;
    margin-top: 10px;
    gap: 5px;

    .arrow {
      font-family: monospace;
    }
  }

  ul,
  ol {
    list-style: none;
  }
}

// .grid2{
//     display: grid;
//     grid-template-columns: 1fr;
//     gap: 20px;
// }

.section.dashboard {
  width: 100%;

  .card {
    // border: 1px solid black;
    border-radius: 20px;
    background: white;
    padding: 20px;
    box-shadow: 0 0 8px rgba(190, 187, 187, 0.589);
  }

  ul,
  ol {
    list-style: none;
  }

  .overview {
    display: grid;
    // grid-template-columns: repeat(auto-fix, minmax(200px, 1fr));
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
  }

  .col-lg-8 {
    .row {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }

  .filter {
    position: absolute;
    display: none;
  }

  .card-title {
    font-size: 1.2rem;
    font-weight: 600;
    opacity: 0.9;
    margin-bottom: 10px;

    span {
      opacity: 0.6;
    }
  }

  .ps-3 {
    font-size: 1.4rem;

    h6 {
      font-size: 1.8rem;
    }
  }

  .d-flex {
    display: flex;
    align-items: center;
    gap: 20px;
    .text-success {
      color: #6f6ff5;
    }

    .card-icon {
      padding: 10px;
      background: #d7beeed0;
      border-radius: 50%;
    }
  }

  .activity-item {
    margin: 10px 10px;
  }

  .table.table-borderless {
    // padding: 10px;
    width: 100%;
  }

  table {
    text-align: left;

    tbody,
    thead {
      tr {
        td,
        th {
          padding: 10px !important;
        }
      }
      th {
        font-variant: small-caps;
      }
    }
  }

  .bg-warning {
    color: #f5a06f;
  }
  .text-primary {
    color: #6f6ff5;
  }

  .bg-success {
    color: #6ff591;
  }
  .bg-danger {
    color: #f56f91;
  }

  .bg-danger,
  .bg-success,
  .bg-warning {
    font-weight: 600;
  }

  .post-item {
    h4 {
      font-size: 1.3rem;
      opacity: 0.6;
      margin-bottom: 5px;
      text-decoration: underline;
    }

    p {
      margin-left: 10px;
      position: relative;

      &::before {
        position: absolute;
        content: "";
        left: -10px;
        width: 5px;
        height: 100%;
        background: #f56f91;
      }
    }
  }
}
