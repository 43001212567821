.admin-employee-profile > form label {
  @apply text-xl text-fadegrey;
}

.admin-employee-profile > form input,
.admin-employee-profile > form select {
  @apply mb-6 mt-1 w-72 rounded-lg border-none bg-accentpurple p-2 font-semibold text-blue outline-none;
}

.admin-employee-profile > form input:read-only,
.admin-employee-profile > form select:read-only {
  @apply text-gray-500;
}
