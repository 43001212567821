.payment-main-section .bottom .left > section .calculations label {
  @apply font-medium;
}

.payment-main-section .bottom .left > section .calculations input {
  @apply mb-6 mt-1 w-full rounded-md bg-accentpurple font-medium text-blue outline-none lg:p-4 lg:text-xl 2xl:px-8 2xl:py-5 2xl:text-3xl;
}

.payment-main-section .bottom .left > section .payment-option label {
  @apply flex cursor-pointer items-center gap-1 rounded-lg border-2 border-accentpurple bg-accentpurple font-semibold hover:border-lighterpurple lg:p-3 lg:text-sm 2xl:px-3 2xl:py-4 2xl:text-base;
}
