.main-content-container {
  @apply rounded-2xl bg-white py-4 lg:px-4 2xl:px-8;
  @apply h-full overflow-y-scroll;
  /* @apply border-2 border-gray-700; */
}

/* The background color for main-content-container of cashier pages are accentpurple not white */
.cashier-bg-color {
  @apply bg-accentpurple;
}
