.details div {
  @apply mt-2 flex items-center justify-between rounded bg-white px-3 py-2 text-fadegrey;
}

.details div p:nth-child(1) {
  @apply w-full font-semibold capitalize text-blue;
}

.details div p:nth-child(2) {
  @apply w-full truncate;
}
