/* @import url("bootstrap/dist/css/bootstrap.min.css"); */

.home-container {
  margin: 3vh 1rem 1vh 18.5vw;
  /* width: calc(100vw - 19vw - 1rem); */
  border-radius: 1.5rem;
  padding: 2.5rem 3.5rem;
  background-color: var(--white);
  /* height: 86vh; */

  /* display: grid; */
  /* grid-gap: 1rem; */
  /* grid-template-columns: repeat(3, 1fr); */
  /* grid-template-rows: 40% 40% 20%; */
  /* grid-template-rows: repeat(2, 4fr) 2fr; */
}

.row {
  row-gap: 20px;
}

.col-lg-4 .card {
  margin-bottom: 20px;
}
.home-container .grid-cards {
  background-color: var(--accent-purple);
  border-radius: 1.25rem;
}

/* .home-container #sect1
{}

.home-container #sect2
{} */

.home-container #sect3 {
  grid-column: 1 / 3;
  grid-row: 2 / 3;
}

.home-container #sect4 {
  grid-column: 1 / 3;
  grid-row: 3 / 4;
}

.home-container #sect5 {
  grid-row: 1 / 4;
  grid-column: 3 / 4;
}

/* --------------------------------- */
/* ----------MEDIA QUERIES---------- */
/* --------------------------------- */

/* XX-Large devices: larger desktops */
@media screen and (max-width: 1440px) {
  .home-container {
    margin: 13vh 1rem 1vh 25vw;
    height: 85vh;
  }
}

@media screen and (max-width: 1366px) {
  /* .home-container
    {

    } */
}
